/* Article.css */

.article {
    margin-bottom: 150px; /* Espaçamento entre os artigos */
  }
  
  .large-image, .small-image {
    width: 100%;
    object-fit: cover;
    display: block;
  }
  
  .large-image {
    height: 450px; /* Altura fixa para imagens grandes */
  }
  
  .small-image {
    height: 300px; /* Altura fixa para imagens pequenas */
  }
  
  .article-textbox {
    margin-top: 10px; /* Espaçamento entre a imagem e o título */
  }
   