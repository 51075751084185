@tailwind base;
@tailwind components;
@tailwind utilities;

.hero-background {
  background-image: url(./assets/Rectangle\ 4.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.review-background {
  background-image: url(./assets/Union.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

body {
  font-family: lora, serif;
}

.article-image {
  width: 100%;
}

.bacgkround-about {
  background-image: url(./assets/Group\ 21.png);
  background-size: 100% 1000px;
  background-repeat: no-repeat;
  background-position: top;
  height: 1100px;
}

@media screen and (min-width: 1024px) {
  .bacgkround-about {
    background-size: 100% 1200px;
  }
}

@media screen and (max-width: 600px) {
  .bacgkround-about {
    background-size: 250% 1000px;
    background-position: top left;
    height: auto;
  }
  .review-background {
    background-size: 140% 100%;
  }
}
